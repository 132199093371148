import * as React from 'react'

import Layout from '../components/core/Layout'

const Uses = () => {
  const usesForWork = [
    '13inch M1 MacBook Pro (2020)',
    'LG UltraWide 35WN73A 35 Inch Curved Monitor',
    'Logitech MX Master 2 Mouse',
    'UK Keychron K2v2 Bluetooth RGB Backlit Hot-Swap Tactile Aluminium Mac/PC Keyboard',
    'Apple Magic Keyboard',
    'Apple Magic Mouse',
    'BenQ e-Reading LED Desk Lamp',
    'Companion 20 multimedia speaker system',
    'Aeron Chair - Office Chairs - Herman Miller',
    'AirPods Pro',
  ]

  const softwareUses = [
    'VS Code',
    'Things3',
    'Spotify',
    'Rectangle',
    'Bear',
    'Grammarly',
    'Jupiter Notebook',
    'Google Colab',
    'Blender',
  ]

  const audioUses = ['Rode NT1-A', 'Apogee Duet', 'Logic Pro X']

  return (
    <Layout>
      <div className="max-w-3xl mx-auto flex flex-col gap-y-6">
        <div className="flex flex-col mx-6 mt-12">
          <h1 className="font-bold text-primary text-5xl mb-4">Uses</h1>
          <p className="mb-4">
            This page is inspired by{' '}
            <a
              className="text-blue-500 underline font-semibold"
              href="https://wesbos.com/uses"
            >
              Wes Bos
            </a>
            ’s{' '}
            <a
              className="text-blue-500 underline font-semibold"
              href="https://uses.tech/"
            >
              uses.tech
            </a>{' '}
            page and{' '}
            <a
              className="text-blue-500 underline font-semibold"
              href="https://leerob.io/"
            >
              leerob.io
            </a>
            ’s website. Here’s everything I’m currently using for work, code,
            music and more.
          </p>
        </div>
        <div className="flex flex-col mx-6">
          <h2 className="font-bold text-primary text-3xl mb-4">Work</h2>
          <ul className="list-disc list-inside">
            {usesForWork.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col mx-6">
          <h2 className="font-bold text-primary text-3xl mb-4">Software</h2>
          <ul className="list-disc list-inside">
            {softwareUses.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col mx-6">
          <h2 className="font-bold text-primary text-3xl mb-4">
            Audio / Music
          </h2>
          <ul className="list-disc list-inside">
            {audioUses.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default Uses
